import { ROLES } from '/functions/shared/constants/roles'

export enum TUTORIAL_IDS {
  REVIEW_PROCESS = 'reviewProcess',
  DEMO_TUTORIAL = 'demoTutorial',
  MANAGER_ANALYTICS = 'managerAnalytics'
}

export const tutorials = {
  [TUTORIAL_IDS.REVIEW_PROCESS]: {
    title: 'Learn About Incompass Reviews',
    role: ROLES.EMPLOYEE,
    contents: [
      // My Reviews
      {
        title: 'My Reviews',
        description:
          'My Reviews is your one-stop-shop for all your reviews. Here, you can submit your active reviews and view completed ones.',
        bulletPoints: [
          {
            title: 'Start an Ongoing Review',
            description:
              'The active review cycles will be listed here, and you can start your reviews by clicking the “Start” button.'
          },
          {
            title: 'Track Review Progress',
            description:
              'After submitting reviews, you can use this page to stay informed with real-time updates on the status of ongoing review cycles.'
          },
          {
            title: 'View Your Results',
            description:
              'Once all reviews are completed, you can access your results here to uncover strengths and areas to develop.'
          }
        ],
        videoUrl: '/assets/videos/employees/my-reviews-to-selection.mp4'
      },
      // Selection
      {
        title: 'Select Colleagues',
        description:
          'Once you start a review process, the first step will be to select colleagues to review. You will review these colleagues in the following steps.',
        bulletPoints: [
          {
            title: 'Suggested Colleagues',
            description:
              'The selection panel will include suggested colleagues based on your team. You can add or remove colleagues, except for the mandatory ones.'
          },
          {
            title: 'Search and Add Colleagues',
            description:
              'You can search for colleagues by name, team, or division, and select them to give feedback.'
          }
        ],
        videoUrl: '/assets/videos/employees/selection-to-questions.mp4'
      },
      // Question card overview
      {
        title: 'Review Your Colleagues',
        description:
          'After selecting your colleagues, you will go through a series of quick review questions. The questions will ask you to provide feedback to your colleagues on company behaviors.',
        bulletPoints: [
          {
            title: 'Using the Sliders',
            description:
              'Use the sliders to indicate how well you think your colleagues display each behavior.'
          },
          {
            title: 'Give Feedback, Assisted with AI',
            description:
              'You can use the feedback panel to give anonymous written feedback to your colleagues and get suggestions from our AI to improve your feedback.'
          }
        ],
        videoUrl: '/assets/videos/employees/questions-to-submit.mp4'
      },
      // Submit page
      {
        title: 'Submit Your Reviews',
        description:
          'Once you have reviewed all your colleagues, you can view a summary of your feedback and submit your reviews.',
        bulletPoints: [
          {
            title: 'View Summary',
            description:
              'You can view a summary of your feedback before submitting your reviews, and go back to make changes if needed.'
          },
          {
            title: 'Submit',
            description:
              'If you are satisfied with your feedback, you can click "Submit Your Feedback" - and you will have completed your reviews!'
          },
          {
            title: 'View Your Inputs',
            description:
              'After submitting your reviews, you can view the feedback you\'ve given to your colleagues by clicking on the "Your Inputs" button.'
          }
        ],
        videoUrl: '/assets/videos/employees/submit-to-my-reviews.mp4'
      }
    ]
  },
  [TUTORIAL_IDS.MANAGER_ANALYTICS]: {
    title: 'How to Use Incompass as a Manager',
    role: ROLES.MANAGER,
    contents: [
      {
        title: 'Incompass for Managers',
        description:
          'Incompass is an innovative tool for understanding and developing your team, in a fast and more objective way. This quick tutorial will guide you through the main features of Incompass.',
        bulletPoints: [
          {
            title: 'Assess and Calibrate Performance Objectively',
            description:
              'Incompass provides an objective and quantifiable performance overview of your team through incentive-compatible peer assessments, empowered by advanced statistics.'
          },
          {
            title: 'Track and Manage Review Cycles with Ease',
            description:
              'You can use the "Review Cycles" panel to stay informed with real-time updates on ongoing reviews and track the progress of your team.'
          },
          {
            title: 'Analyze Team Insights',
            description:
              'The "Analytics" panel let’s you analyze the results of your direct reports and view reports on your team’s performance.'
          }
        ],
        videoUrl: '/assets/videos/manager/manager-dashboard.mp4'
      },
      {
        title: 'Understand Your Team',
        description:
          'With an accurate and quantifiable performance calibration, Incompass let’s you discover comprehensive insights about your team and unlock precise learning & development opportunities for everyone.',
        bulletPoints: [
          {
            title: 'Innovative Team Insights',
            description:
              'Incompass let’s you get an accurate bird’s eye view of your team’s overall performance, as well as how each individual performs.'
          },
          {
            title: 'Easier, Data-Driven Learning & Development Conversations',
            description:
              'The data analyses at both individual and organizational levels provides you with the insights you need to have data-driven development conversations with your direct reports.'
          }
        ],
        videoUrl: '/assets/videos/manager/manager-results.mp4'
      }
    ]
  },
  [TUTORIAL_IDS.DEMO_TUTORIAL]: {
    title: 'Overview of Incompass for Admins',
    role: ROLES.ADMIN,
    contents: [
      {
        title: 'Welcome.',
        description:
          'Incompass is an innovative tool for understanding and developing your workforce, in a fast, objective, and scalable way. This quick tutorial will guide you through the main features of Incompass.',
        bulletPoints: [
          {
            title: 'Assess and Calibrate Performance Objectively',
            description:
              'Incompass provides an objective and quantifiable performance overview of your entire organization through incentive-compatible peer assessments, empowered by advanced statistics.'
          },
          {
            title: 'Track and Manage Review Cycles with Ease',
            description:
              'Incompass’s efficient interface helps you stay informed with real-time updates on ongoing reviews, and allows you to make customizations to fit your organization’s needs.'
          }
        ],
        imageSrc: '/assets/images/admin-review-cycles.png',
        imageAlt: 'Admin Dashboard'
      },
      {
        title: 'Understand Your Workforce',
        description:
          'With an accurate and quantifiable performance calibration, Incompass let’s you discover comprehensive insights about your entire workforce, make people decisions, and unlock precise learning & development opportunities for everyone.',
        bulletPoints: [
          {
            title: 'Innovative Workforce Insights',
            description:
              'Incompass let’s you get an accurate bird’s eye view of your organization’s overall performance, as well as how each individual performs.'
          },
          {
            title: 'Easier People Decisions',
            description:
              'The data analyses at both individual and organizational levels provides you with the insights you need to make informed people decisions.'
          }
        ],
        imageSrc: '/assets/images/admin-analytics.png',
        imageAlt: 'Review Results'
      },
      {
        title: 'Fast and Accurate Reviews',
        description:
          'Incompass’s unique approach to peer reviews incentives users to provide as accurate inputs as possible through a simple, quick, and customizable interface.',
        bulletPoints: [
          {
            title: 'Effective Employee Reviews',
            description:
              'Designed to make the review process as easy as possible for everyone, Incompass minimizes the time it takes to collect peer assessments, while ensuring the accuracy of the results.'
          },
          {
            title: 'Customizable Reviews and Results',
            description:
              'Incompass’s simple and adaptable interfaces let’s you customize the review process to fit your organization’s needs and Learning & Development goals.'
          }
        ],
        imageSrc: '/assets/images/review-questions.png',
        imageAlt: 'Review Results'
      }
    ]
  }
}
