import { useRoutes } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { Provider as ReduxProvider } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { RTL } from '/src/components/rtl'
import { SplashScreen } from '/src/components/splash-screen'
import { Toaster } from '/src/components/toaster'
import {
  SettingsConsumer,
  SettingsProvider
} from '/src/contexts/settings-context'
import { AuthConsumer, AuthProvider } from '/src/contexts/auth/firebase-context'

import { useNprogress } from '/src/hooks/use-nprogress'
import { routes } from '/src/routes'
import { store } from '/src/store'
import { createTheme } from '/src/theme'
// Remove if simplebar is not used
import 'simplebar-react/dist/simplebar.min.css'
// Remove if mdx editor is not used
import '@mdxeditor/editor/style.css'
// React toastify styles
import 'react-toastify/dist/ReactToastify.css'
import { QueryClientProvider, QueryClient } from 'react-query'
import { Scrollbar } from '/src/components/scrollbar'
import { useMediaQuery } from '@mui/material'
import { MobileWarning } from '/src/components/mobile-warning'
import CheckConnection from '/src/components/common/CheckConnection/check-connection'

export const App = () => {
  const smallScreen = useMediaQuery('(max-width:700px)')

  useNprogress()

  const element = useRoutes(routes)

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        retry: 0,
        suspense: true
      }
    }
  })

  return (
    <ReduxProvider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <AuthConsumer>
              {(auth) => (
                <SettingsProvider>
                  <SettingsConsumer>
                    {(settings) => {
                      const theme = createTheme({
                        customizationConfig: settings.customizationConfig,
                        direction: settings.direction,
                        paletteMode: settings.paletteMode,
                        responsiveFontSizes: settings.responsiveFontSizes
                      })

                      // Prevent guards from redirecting
                      const showSplashScreen = !auth.isInitialized

                      return (
                        <ThemeProvider theme={theme}>
                          <Helmet>
                            <meta
                              name="color-scheme"
                              content={settings.paletteMode}
                            />
                            <meta
                              name="theme-color"
                              content={theme.palette.neutral[900]}
                            />
                          </Helmet>
                          <RTL direction={settings.direction}>
                            <CssBaseline />
                            {showSplashScreen ? (
                              <SplashScreen />
                            ) : (
                              <Scrollbar
                                sx={{
                                  maxHeight: '100vh',
                                  overflow: 'auto'
                                }}
                              >
                                {smallScreen ? (
                                  <MobileWarning />
                                ) : (
                                  <CheckConnection>{element}</CheckConnection>
                                )}
                              </Scrollbar>
                            )}
                            <Toaster />
                          </RTL>
                        </ThemeProvider>
                      )
                    }}
                  </SettingsConsumer>
                </SettingsProvider>
              )}
            </AuthConsumer>
          </AuthProvider>
        </QueryClientProvider>
      </LocalizationProvider>
    </ReduxProvider>
  )
}
