import { lazy, Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { Layout as DashboardLayout } from '/src/layouts/dashboard'
import ErrorBoundary from '/src/components/sentry-error-boundary'
import DashboardLoading from '/src/components/dashboard-loading'

// Redirect to homepage according to user role
import RedirectHandler from '/src/pages/dashboard/index.jsx'

// Admin pages
const CreateEvaluation = lazy(() => import('/src/pages/dashboard/admin/create'))
const UpdateEvaluation = lazy(() =>
  import('/src/pages/dashboard/admin/update.jsx')
)
const AdminManagement = lazy(() =>
  import('/src/pages/dashboard/admin/management')
)
const AdminDashboard = lazy(() =>
  import('/src/pages/dashboard/admin/index.jsx')
)
const AdminEvaluationList = lazy(() =>
  import('/src/pages/dashboard/admin/reviews.jsx')
)
const CycleDetails = lazy(() =>
  import('/src/pages/dashboard/admin/cycle-details.jsx')
)

// Manager Pages
const ManagerDashboard = lazy(() =>
  import('/src/pages/dashboard/manager/index.jsx')
)
const ManagerEvaluationList = lazy(() =>
  import('/src/pages/dashboard/manager/reviews.jsx')
)
const ManagerProgressPage = lazy(() =>
  import('/src/pages/dashboard/manager/progress.jsx')
)

// Reviews Pages
const SelectionPage = lazy(() =>
  import('/src/pages/dashboard/reviews/selection.jsx')
)
const SubmitPage = lazy(() => import('/src/pages/dashboard/reviews/submit.jsx'))
const MyReviews = lazy(() => import('/src/pages/dashboard/my-reviews.jsx'))
const QuestionsPage = lazy(() =>
  import('/src/pages/dashboard/reviews/questions.jsx')
)
const EmployeeResultsPage = lazy(() =>
  import('/src/pages/dashboard/reviews/results.jsx')
)

export const dashboardRoutes = [
  {
    path: '/',
    element: (
      <DashboardLayout>
        <ErrorBoundary>
          <Suspense fallback={<DashboardLoading />}>
            <Outlet />
          </Suspense>
        </ErrorBoundary>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <RedirectHandler />
      },
      {
        path: 'admin',
        children: [
          {
            index: true,
            element: <AdminDashboard />
          },
          {
            path: 'reviews',
            element: <AdminEvaluationList />
          },
          {
            path: ':evaluationId/details',
            element: <CycleDetails />
          },
          {
            path: ':evaluationId/update',
            element: <UpdateEvaluation />
          },
          {
            path: 'create',
            element: <CreateEvaluation />
          },
          {
            path: 'management',
            element: <AdminManagement />
          }
        ]
      },
      {
        path: 'manager',
        children: [
          {
            index: true,
            element: <ManagerDashboard />
          },
          {
            path: 'reviews',
            element: <ManagerEvaluationList />
          },
          {
            path: 'reviews/:evaluationId/progress',
            element: <ManagerProgressPage />
          }
        ]
      },
      {
        path: 'reviews',
        children: [
          {
            index: true,
            element: <MyReviews />
          },
          {
            path: ':evaluationId',
            element: <SelectionPage />
          },
          {
            path: ':evaluationId/questions',
            element: <QuestionsPage />
          },
          {
            path: ':evaluationId/submit',
            element: <SubmitPage />
          },
          {
            path: ':evaluationId/results',
            element: <EmployeeResultsPage />
          }
        ]
      }
    ]
  }
]
